import React from 'react'
import './PagenotFound.css'


function PagenotFound() {
  return (
    <div className='pagenotfound'>
      <h1 className='notfound'>404</h1>
      <br></br>
      <p>Page Not Found</p>
    </div>
  )
}

export default PagenotFound
