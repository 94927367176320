import React from 'react'
import './Footer.css'



function Footer() {
  return (
    <div className='footer'>
      Copyright © Omeir Khoder 2023 - Developed by Team10 B09
    </div>
  )
}

export default Footer
